.whatsapp-logo {
    width: 100%;
    height: auto;
}

.whatsapp-logo-container {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 80px;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: auto;
}

.copyright-line {
    border-top: 1px solid rgba(211, 211, 211, 0.555);
    text-align: center;
    color: #2a6de9;
    padding-top: 4px;
}

.client-questions {
    padding: 7px 0px;
}

.attribution {
    font-size: smaller;
    // text-align: center;
    justify-content: center;
    padding-top: 10px;
    color: #075b6a;
}